import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ElectronService } from '..';
import { IPC_CHANNELS } from '../../../../../electron-utils/electron.model';
import { BrowserNotificationService, IncomingCallNotification } from '../notifications';
import { SESSION_TYPE } from '../sip';
import { Call } from './call.model';
import { CallService } from './call.service';

@Injectable({
  providedIn: 'root'
})
export class IncomingCallService {

  private _callNotifications: IncomingCallNotification[] = [];
  public onIncomingCallNotificationsChanged$: Subject<IncomingCallNotification[]> = new Subject();
  public incomingCallNotificationAdded$: Subject<IncomingCallNotification> = new Subject();
  public incomingCallNotificationRemoved$: Subject<number> = new Subject();

  constructor(
    private browserNotification: BrowserNotificationService,
    private electronService: ElectronService,
  ) {
  }

  public get callNotifications(): IncomingCallNotification[] {
    return this.callNotifications;
  }

  public addNotification(incomingCall: IncomingCallNotification) {
    this._callNotifications.push(incomingCall);
    this.onIncomingCallNotificationsChanged$.next(this._callNotifications);
    this.incomingCallNotificationAdded$.next(incomingCall);
  }

  public removeNotificationWithIndex(index: number): void {
    const not = this._callNotifications.splice(index, 1)[0];
    if(not.subscription) not.subscription.unsubscribe();
    this.onIncomingCallNotificationsChanged$.next(this._callNotifications);
    this.incomingCallNotificationRemoved$.next(not.id);
  }

  public removeNotificationByCall(call: Call): void {
    const idx = this._callNotifications.findIndex((incoming: IncomingCallNotification) => call.id === incoming.id);
    if(idx !== -1) this.removeNotificationWithIndex(idx);
  }

  public notifyIncomingCall(call: Call): void {
    const incomingCallNotification: IncomingCallNotification = {
      id: call.id,
      call: call,
      isInApp: document.visibilityState === 'visible'
    };
    // if we are in electron, we need to check if window is focused. If not we need to open a new window with the notification
    // if we are in browser, check document.visibilityState https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState
    // and if not visible throw a OS notification (if enabled)
    if(this.electronService.isElectron) {
      this.openElectronNotification(incomingCallNotification);
    } else {
      if(!incomingCallNotification.isInApp) {
        this.openBrowserNotification(incomingCallNotification);
      }
    }
    this.addNotification(incomingCallNotification);
  }

  private openBrowserNotification(notificationModel: IncomingCallNotification): void {
    const notification: Notification = this.browserNotification.notify(`Incoming call from ${notificationModel.call.fullname}`, {
      body: `${notificationModel.call.number}`,
      badge: 'assets/icons/favicon.png',
      requireInteraction: true
    });
    const callback = () => {
      if(document.visibilityState === 'visible') {
        notification.close();
      }
      document.removeEventListener('visibilitychange', callback);
    }
    document.addEventListener('visibilitychange', callback);
    notification.addEventListener('click', () => {
      window.focus();
      notification.close();
      document.removeEventListener('visibilitychange', callback);
    });
    notificationModel.subscription = notificationModel.call.sessionContainer.changeTypeListener.subscribe((v) => {
      if(v === SESSION_TYPE.TERMINATED) {
        notification.close();
      }
    });
  }

  private openElectronNotification(notificationModel: IncomingCallNotification): void {
    if (typeof(notificationModel.id) != "number") return;

    this.createElectronNotification(notificationModel);

    const callback = () => {
      if(document.visibilityState === 'visible') {
        this.sendMessageToCloseNotification(notificationModel.call.id);
      }
      document.removeEventListener('visibilitychange', callback);
    }
    document.addEventListener('visibilitychange', callback);

    notificationModel.subscription = notificationModel.call.sessionContainer.changeTypeListener.subscribe((v) => {
      if(v === SESSION_TYPE.TERMINATED) {
        this.sendMessageToCloseNotification(notificationModel.call.id);
      }
    });
  }

  private createElectronNotification(notificationModel: IncomingCallNotification): void {
    const notification = {
      callId: notificationModel.call.id,
      fullname: notificationModel.call.fullname,
      callee: notificationModel.call.sessionContainer.session.remoteDisplayName.name.local || null
    };
    this.electronService.ipcRenderer.send(IPC_CHANNELS.OPEN_NEW_INCOMING_WINDOW, notification.callId, JSON.stringify(notification));
  }

  private sendMessageToCloseNotification(callId: number) {
    if(this.electronService.isElectron){
      this.electronService.ipcRenderer.send(IPC_CHANNELS.CLOSE_INCOMING_WINDOW, callId);
    }
  }
}
