export interface IRomulusPostMessage {
  origin: string;
  type: RomulusMessageTypes;
  id?: string;
  payload: IRomulusLeadPayload | IRomulusMessagePayload | IRomulusLiveChatRequestPayload;
}

export interface IRomulusMessagePayload {
  data: {
    messagedBy: string;
    data: string;
    uuid: string;
    _bot: string;
    notificationType: NotificationTypes;
  }
  message: string;
  title: string;
}

export interface IRomulusLeadPayload {
  data: {
    message: string;
  };
  message: string;
  title: string;
}

export interface IRomulusLiveChatRequestPayload {
  data: {
    message: string;
  };
  message: string;
  title: string;
}

export enum RomulusMessageTypes {
  MESSAGE = 'message',
  LEAD = 'lead',
  LIVE_CHAT_REQUEST = 'live-chat-request'
}

export enum NotificationTypes {
  NEW_MESSAGE = 'NEW_MESSAGE'
}