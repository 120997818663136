import { StructuredAddressData } from "ng-voxloud";
import { PRESENCE_STATUS } from "../sip";
import { PaginatedResponse } from "../../../shared/models/pagination.model";

export enum ADDRESSBOOK_TYPES {
  INTERNAL_USER = 'internal-user',
  INTERNAL_GROUP = 'internal-group',
  EXTERNAL = 'external',
  EXTENSION = 'extension',
  CRM = 'crm',
  LOCAL = 'local'
};

export enum GROUP_TYPES {
  GROUP = 'GROUP',
  CASCADE = 'CASCADE',
  QUEUE = 'QUEUE'
}

export interface InternalUserResponse {
  user_id: string;
  email: string;
  firstName: string;
  lastName: string;
  extension: string;
  phoneNumber?: string;
  note?: string;
  presence_id?: string;
}

export interface InternalGroupResponse {
  group_id: string;
  name: string;
  description?: string;
  extension: string;
  group_type: GROUP_TYPES
}

export interface VCardTel {
  label: string | undefined | AddressbookRecordPhoneLabel;
  number: string;
  type?: string;
  capability?: string[];
  group?: string;
}

export interface VCardEmail {
  label: string | undefined | AddressbookRecordEmailLabel;
  email: string;
  type?: string;
  capability?: string[];
  group?: string;
}

export interface VCardImportStatistics {
  total: number;
  skipped: number;
  added: number;
  errored: number;
}

// Can be used to separate the type of number (i.e. work, home) from it's capability
export const TEL_CAPABILITIES = ['text', 'voice', 'fax', 'cell', 'video', 'pager', 'textphone'];

// Can be used to separate the type of email (i.e. work, home) from it's capability
export const EMAIL_CAPABILITIES = ['aol', 'applelink', 'attmail', 'cis', 'eworld', 'internet', 'ibmmail', 'mcimail', 'powershare', 'prodigy', 'tlx', 'x400'];

export interface CrmLookupResponse {
  "Contact Name": string;
  Title: string;
  Number: CrmLookupNumbers[];
  Email: CrmLookupEmails[];
  "Crm Link": string;
  Id: string;
  CRM: string;
  "Company Name": string;
  CRM_Icon: string;
  phonesList?: CrmLookupPhonelist[];
}

export interface CrmLookupNumbers {
  original: string;
  stripped: string;
  national: string;
  E164: string;
  national_stripped: string;
}

export interface CrmLookupEmails {
  label: string;
  value: string;
}

export interface CrmLookupPhonelist {
  label: string;
  value: string;
}

export interface CrmLookupFilters {
  phone?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  company?: string;
}

export interface CrmLookupPhoneFilter {
  phone: string;
}

export interface AddressbookResponse {
  groups: InternalGroupResponse[];
  users: InternalUserResponse[];
}

export interface AddressbookRecord {
  id?: number;
  remoteId?: string;
  type: ADDRESSBOOK_TYPES;
  firstName: string;
  lastName?: string;
  defaultNumber: AddressbookRecordNumber;
  numbers: AddressbookRecordNumber[];
  hash?: string;
  link?: string;
  avatar?: string;
  groupType?: GROUP_TYPES;
  indexableTelNumbers?: string[];
  icon?: string;
  CRM?: string;
  crm_contact_url?: string,
  crm_logo?: string;
  favorite?: number;
  presenceId?: string;
  status?: PRESENCE_STATUS;
  emails?: APIAddressbookRecordEmail[];
  notes?: string;
  job_title?: string;
  company_name?: string;
  website?: string;
  address?: APIAddressRecord;
  source?: ApiAddressbookRecordSources;
}

export interface RecordsToDisplayModel {
  all: AddressbookRecord[];
  filtered: AddressbookRecord[];
  displayed: AddressbookRecord[];
}

export interface AddressbookRecordNumber {
  label: AddressbookRecordPhoneLabel;
  number: string;
}

export interface FavoriteRecord{
  id?: number;
  contactId: string;
  type: FavoriteContactType;
}

export enum FavoriteContactType {
  INTERNAL = 'internal',        // Users and groups
  LOCAL = 'local',              // Local address book
  REMOTE = 'remote'             // Contacts from backend
}

// API

export interface APIAddressbookRecord {
  id?: number;
  first_name: string;
  last_name: string;
  emails: APIAddressbookRecordEmail[];
  phones: APIAddressbookRecordPhone[];
  addresses: APIAddressRecord[];
  source?: ApiAddressbookRecordSources;
  job_title?: string;
  company_name?: string;
  website?: string;
  crm_name?: string;
  crm_contact_url?: string;
  crm_logo?: string;
  created_at?: Date;
  updated_at?: Date;
}

export enum ApiAddressbookRecordSources {
  INTERNAL = 'INTERNAL',
  PUBLIC_API = 'PUBLIC_API',
  IMPORT = 'IMPORT',
  USER = 'USER',
  INTEGRATION = 'INTEGRATION'
}

export interface PaginatedAPIAddressbookContacts extends PaginatedResponse {
  content: APIAddressbookRecord[];
}

export interface APIAddressbookRecordEmail {
  id?: number;
  email: string;
  label: AddressbookRecordEmailLabel;
}

export interface APIAddressbookRecordPhone {
  id?: number;
  number: string;
  label: AddressbookRecordPhoneLabel
}

export interface APIAddressRecord {
  id?: number;
  street: string;
  postal_code: string;
  city: string;
  province: string;
  country: string;
  address_line: string;
}

export enum AddressbookRecordEmailLabel {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  HOME = 'HOME',
  JOB = 'JOB',
  OTHER = 'OTHER'
}


export enum AddressbookRecordPhoneLabel {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  EXTENSION = 'EXTENSION',
  MOBILE = 'MOBILE',
  HOME = 'HOME',
  JOB = 'JOB',
  OTHER = 'OTHER'
}

export enum APIAddressbookSources {
  INTERNAL = 'INTERNAL',
  PUBLIC_API = 'PUBLIC_API',
  IMPORT = 'IMPORT',
  INTEGRATION = 'INTEGRATION'
}

export enum APIAddressbookSearchProperties {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  JOB_TITLE = 'JOB_TITLE',
  COMPANY_NAME = 'COMPANY_NAME',
  WEBSITE = 'WEBSITE'
}

export interface APIAddressbookPermission {
  id: number;
  aggregate_id: number;
  company_id: number;
  can_update: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface APIAddressbookFilter {
  first_name?: string;
  last_name?: string;
  company_id?: number;
  crm_contact_id?: string;
  phone?: string;
  email?: string;
  job_title?: string;
  company_name?: string;
  website?: string;
  crm_name?: string;
  crm_contact_url?: string;
  source?: APIAddressbookSources;
  street?: string;
  postal_code?: string;
  city?: string;
  province?: string;
  country?: string;
  create_at_range?: {
    from: Date;
    to: Date;
  },
  updated_at_range?: {
    from: Date;
    to: Date;
  }
  page?: number;
  size?: number;
  input?: string;
  properties?: APIAddressbookSearchProperties[];
  ids?: string[];
}

export interface ImportResponse {
  id: number;
  company_id: number;
  user: {
    id: number;
    aggregate_id: number;
    company_id: number;
    can_update: boolean;
    created_at: Date;
    updated_at: Date;
  },
  url: string;
  content_type: CONTACT_CONTENT_TYPE;
  status: CONTACT_STATUS;
  file_format: {
    mappings: ContactMapping[],
    columns_type: CONTACT_COLUMN_TYPE;
  },
  created_at: Date;
  updated_at: Date;
}

export enum CONTACT_CONTENT_TYPE {
  CSV = "CSV",
  VCARD = "VCARD"
}

enum CONTACT_STATUS {
  CREATED = "CREATED",
  PREVIEW = "PREVIEW"
}

export interface ContactMapping {
  column: string;
  contact_property?: string;
  label?: string;
}

enum CONTACT_COLUMN_TYPE {
  NAME,
  INDEX
}
