import { Component, OnDestroy, OnInit } from '@angular/core';
import { IncomingCallService } from '../../../core/services/call/incoming-call.service';
import { IncomingCallNotification } from '../../../core/services/notifications';
import { Subscription } from 'rxjs';
import { RomulusInboxService } from '../../services/romulus/romulus.service';
import { IRomulusPostMessage } from '../../models/integrations/romulus.model';

@Component({
  selector: 'main-in-app-notifications',
  templateUrl: './main-in-app-notifications.component.html',
  styleUrls: ['./main-in-app-notifications.component.scss']
})
export class MainInAppNotificationsComponent implements OnInit, OnDestroy {

  public notifications: any[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private incomingCallService: IncomingCallService,
    private romulusService: RomulusInboxService
  ){}

  ngOnInit(): void {
    this.subscriptions.push(
      this.incomingCallService.incomingCallNotificationAdded$.subscribe((incomingCall: IncomingCallNotification) => {
        this.notifications.push(incomingCall);
      })
    );

    this.subscriptions.push(
      this.incomingCallService.incomingCallNotificationRemoved$.subscribe((id: number) => {
        const index = this.notifications.findIndex((v) => {
          return v.id === id;
        });
        if(index > -1) {
          this.notifications.splice(index, 1);
        };
      })
    );

    this.subscriptions.push(
      this.romulusService.notificationAdded$.subscribe((messageNotification: IRomulusPostMessage) => {
        this.notifications.push(messageNotification);
      })
    );

    this.subscriptions.push(
      this.romulusService.notificationRemoved$.subscribe((id: string) => {
        const index = this.notifications.findIndex((v) => {
          if(!v.id) return false;
          return v.id === id;
        });
        if(index > -1) {
          this.notifications.splice(index, 1);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

}
