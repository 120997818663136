import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RomulusInboxService } from '../../services/romulus/romulus.service';
import { IRomulusPostMessage, RomulusMessageTypes } from '../../models/integrations/romulus.model';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'romulus-chat-notification',
  templateUrl: './romulus-chat-notification.component.html',
  styleUrls: ['./romulus-chat-notification.component.scss']
})
export class RomulusChatNotificationComponent implements OnInit, OnDestroy {

  public notificationTypes = RomulusMessageTypes;

  @Input() notification: IRomulusPostMessage;

  public autoHideDelay: number = 10 * 1000; //seconds

  constructor(
    private romulusService: RomulusInboxService,
    private router: Router
  ){}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public openChat(): void {
    this.dismiss();
    this.router.navigate(['home', 'inbox']);
  }

  public dismiss(evt: InputEvent = null): void {
    evt?.stopImmediatePropagation();
    evt?.stopPropagation();
    this.romulusService.removeNotification(this.notification);
  }

  public onHide() {
    this.dismiss();
  }
}
