import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CallService } from '../../../core/services';
import { IncomingCallNotification } from '../../../core/services/notifications/notification.model';
import { SIP_STATUS_CODES } from '../../../core/sipjs/models';

@Component({
  selector: 'in-app-call-notification',
  templateUrl: './in-app-call-notification.component.html',
  styleUrls: ['./in-app-call-notification.component.scss']
})
export class InAppCallNotificationComponent implements OnInit, OnDestroy {

  public notifications: IncomingCallNotification[] = [];

  @Input() notification: IncomingCallNotification;

  constructor(
    private callService: CallService
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }

  public onReject(incoming: IncomingCallNotification): void {
    this.callService.hangup(incoming.call, {
      rejectCode: SIP_STATUS_CODES.BUSY_HERE
    });
  }

  public onAccept(incoming: IncomingCallNotification): void {
    this.callService.acceptCall(incoming.call);
  }

}
