import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { NgbCollapseModule, NgbDropdownModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { PageNotFoundComponent } from './components/';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileHeaderComponent } from './components/profile-header/profile-header.component';
import { LaddaModule } from 'angular2-ladda';
import { CallHistoryTypePipe, HightlightPipe, RecordFullnamePipe, RecordNamePipe, SecondsToMinutesPipe, TimesPipe } from './pipes';
import { MainActionButtonComponent } from './components/main-action-button/main-action-button.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { FavoriteIconComponent } from './components/favorite-icon/favorite-icon.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ConfirmationComponent } from './components/notifications/confirmation/confirmation.component';
import { ReferralService } from '../settings/services/referral/referral.service';
import { DialerButtonComponent } from './components/dialer-button/dialer-button.component';
import { DialerComponent } from './components/dialer/dialer.component';
import { StartCallModalComponent } from './components/start-call-modal/start-call-modal.component';
import { InAppCallNotificationComponent } from './components/in-app-call-notification/in-app-call-notification.component';
import { IntegrationsService } from '../settings/services/integrations';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CallDirectionIconComponent } from './components/call-direction-icon/call-direction-icon.component';
import { ContactDetailsModalComponent } from './components/contact-details-modal/contact-details-modal.component';
import { ReconnectionModalComponent } from './components/reconnection-modal/reconnection-modal.component';
import { SpyCallButtonComponent } from './components/spy-call-button/spy-call-button.component';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './stores/user/reducer/user.reducer';
import { CallButtonComponent } from './components/call-button/call-button.component';
import { RomulusChatNotificationComponent } from './components/romulus-chat-notification/romulus-chat-notification.component';
import { MainInAppNotificationsComponent } from './components/main-in-app-notifications/main-in-app-notifications.component';

const COMPONENTS = [
  PageNotFoundComponent,
  ProfileHeaderComponent,
  MainActionButtonComponent,
  AvatarComponent,
  FavoriteIconComponent,
  ConfirmationComponent,
  DialerButtonComponent,
  DialerComponent,
  StartCallModalComponent,
  InAppCallNotificationComponent,
  PaginationComponent,
  CallDirectionIconComponent,
  ContactDetailsModalComponent,
  ReconnectionModalComponent,
  SpyCallButtonComponent,
  CallButtonComponent,
  RomulusChatNotificationComponent,
  MainInAppNotificationsComponent
]

const PIPES = [
  TimesPipe,
  CallHistoryTypePipe,
  RecordNamePipe,
  SecondsToMinutesPipe,
  HightlightPipe,
  RecordFullnamePipe
]

const DIRECTIVES = [
  AutofocusDirective
]

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule
]

const EXPORT_MODULES = [
  TranslateModule,
  FormsModule,
  LaddaModule,
  ReactiveFormsModule,
  NgxSkeletonLoaderModule,
  NgbDropdownModule,
  NgbTooltipModule,
  NgbCollapseModule,
  NgbToastModule
]

const SERVICES = [
  ReferralService,
  IntegrationsService
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ],
  imports: [
    ...MODULES,
    ...EXPORT_MODULES
  ],
  providers: [
    ...SERVICES,
    ...PIPES
  ],
  exports: [
    ...EXPORT_MODULES,
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ]
})
export class SharedModule { }
