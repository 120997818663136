import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'call-button',
  templateUrl: './call-button.component.html',
  styleUrls: [
    '../../styles/contact-list/contact-list.scss',
    './call-button.component.scss'
  ]
})
export class CallButtonComponent implements OnInit {

  @Input() size: number = 40;
  @Input() disabled: boolean = false;
  @Input() isTransfer: boolean = false;
  @Input() alternative: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
